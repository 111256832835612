import LocalStorage from "utils/localstorage.service";
import http from "./http.service";
// import SocketService from "./socket.service";
import Promisable from "./promisable.service";
import { authActions } from "redux/slices/auth";
import OneSignalService from "./onesignal.service";
import { getAppDispatch } from "utils/dispatch.util";
import SocketService from "./socket.service";
import { config } from "config";

const AuthService = {
  authAction: (user: any, dispatch: any) => {
    dispatch?.(authActions.setUser(user));

    OneSignalService.connect(user._id);
    SocketService.connect(user);
  },
  login: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/auth/login`, data)
    );

    if (success) {
      const { user, jwtToken } = success?.data?.data;
      LocalStorage.setItem("token", `Bearer ${jwtToken}`);
      AuthService.authAction(user, dispatch);
      // dispatch?.(authActions.setUser(user));

      // OneSignalService.connect(user._id);
      // SocketService.connect(user);
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  logout: () => {
    const dispatch = getAppDispatch();

    SocketService.disconnect();
    OneSignalService.disconnect();

    localStorage.removeItem("token");
    localStorage.removeItem("vC");
    localStorage.removeItem("vDC");

    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
  },
  forgot: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/auth/forgot-password`, data)
    );

    const { otpExpiry = null } = success?.data?.data || {};

    dispatch?.(authActions.setLoading(false));
    return [otpExpiry, error];
  },
  verifyOtp: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/auth/verify-otp`, data)
    );

    const { verified = null } = success?.data?.data || {};

    dispatch?.(authActions.setLoading(false));
    return [verified, error];
  },
  reset: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/auth/reset-password`, data)
    );

    const { user = null } = success?.data?.data || {};

    dispatch?.(authActions.setLoading(false));
    return [user, error];
  },

  createToken: async (_id: string, role: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const url =
      role == "company" ? config.APP_HR_COMPANY_URL : config.APP_HR_DRIVER_URL;
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/auth/generate-token/${_id}/${role}`)
    );

    if (success?.data?.data) {
      const { user, jwtToken } = success?.data?.data;
      window.open(`${url}?token=${jwtToken}`, "_blank");
    }

    dispatch?.(authActions.setLoading(false));
    return [true, error];
  },
};
export default AuthService;
