import { ShiftState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: ShiftState = {
  count: 0,
  refresh: 0,
  shifts: [],
  shift: null,
  loading: true,
  shiftOptions: [],
  current_filters: {},
  sorting: {},
  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
  driverShift: null,
};

export const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    clear: (state) => {
      state.shiftOptions = [];
      state.shifts = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addShift: (state, action) => {
      state.shifts.unshift(action.payload);
    },
    driverShift: (state, action) => {
      state.driverShift = action.payload;
    },
    updateShift: (state, action) => {
      const { id, shift } = action.payload;
      state.shifts.every(({ _id }, i) => {
        if (id === _id) {
          state.shifts[i] = shift;
          return false;
        }
        return true;
      });
    },
    setShift: (state, action) => {
      state.shift = action.payload;
    },
    setShifts: (
      state,
      action: PayloadAction<{ shifts: any[]; count: number }>
    ) => {
      const { count, shifts } = action.payload;

      state.count = count;
      state.shifts = shifts;
      state.refreshLoader = false;
      // state.shiftOptions = options;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteShift: (state, action) => {
      const id = action.payload;
      state.shifts.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.shifts.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const shiftReducer = shiftSlice.reducer;

export const shiftActions = shiftSlice.actions;
export default shiftReducer;
