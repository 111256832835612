import { PartnerState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PartnerState = {
  partners: [],
  partner: null,
  loading: true,
  refreshLoader: false,
  sorting: {},
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      state.refreshLoader = action.payload;
    },
    addPartner: (state, action) => {
      state.partners.unshift(action.payload);
    },
    updatePartner: (state, action) => {
      const { id, partner } = action.payload;
      state.partners.every(({ _id }, i) => {
        if (id === _id) {
          state.partners[i] = partner;
          return false;
        }
        return true;
      });
    },
    setPartner: (state, action) => {
      state.partner = action.payload;
    },
    setPartners: (state, action: PayloadAction<{ partners: any[] }>) => {
      const { partners } = action.payload;
      console.log("partners action", partners);
      state.partners = partners;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    deletePartner: (state, action) => {
      const id = action.payload;
      state.partners.every(({ _id }, i) => {
        if (id === _id) {
          state.partners.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const partnerReducer = partnerSlice.reducer;

export const partnerActions = partnerSlice.actions;
export default partnerReducer;
