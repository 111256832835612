import moment from "moment";

const ObjectService = {
  anyError: (shiftErrors: any) => {
    return Object.values(shiftErrors).every((value) => value === false);
  },
  getFullName: (person: any, allowMX: boolean = true) => {
    let fullName = "";

    if (person) {
      const { title, last_name, first_name } = person;

      if (title) fullName += `${title} `;
      if (first_name) fullName += `${first_name} `;
      if (last_name) fullName += `${last_name}`;
    }

    return fullName.trim();
  },
  getObjectPropertiesSum: (values: any) => {
    let sum = 0;

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const element = values[key];
        sum += Number(element);
      }
    }

    return sum;
  },
  shiftError: (shift: any, t: any) => {
    const list = [];
    if (shift?.shiftErrors && !ObjectService.anyError(shift?.shiftErrors)) {
      if (shift?.shiftErrors?.nightShift) {
        list.push(t("Night Shift"));
      }
      if (shift?.shiftErrors?.dayShift) {
        list.push(t("Day Shift"));
      }
      if (shift?.shiftErrors?.minKm) {
        list.push(t("Min Km"));
      }
      if (shift?.shiftErrors?.maxKm) {
        list.push(t("Max Km"));
      }
      if (shift?.shiftErrors?.minTime) {
        list.push(t("Min Time"));
      }
      if (shift?.shiftErrors?.maxTime) {
        list.push(t("Max Time"));
      }
    }
    return list?.toString();
  },

  // getDuration: ({ startDate, startTime, endDate, endTime }: any) => {
  //   const startDateTime = moment(
  //     `${startDate.split("T")[0]} ${startTime
  //       .split("T")[1]
  //       .split(":")
  //       .slice(0, 2)
  //       .join(":")}:00`,
  //     "YYYY-MM-DD HH:mm:ss"
  //   );
  //   const endDateTime = moment(
  //     `${endDate.split("T")[0]} ${endTime
  //       .split("T")[1]
  //       .split(":")
  //       .slice(0, 2)
  //       .join(":")}:00`,
  //     "YYYY-MM-DD HH:mm:ss"
  //   );
  //   const totalHours = endDateTime.diff(startDateTime, "hours", true);
  //   return totalHours.toFixed(2);
  // },
  getDuration: ({ startDate, startTime, endDate, endTime }: any) => {
    const startDateTime = moment(startDate); // Parse as ISO 8601
    const startTimeOnly = moment(startTime).format("HH:mm:ss"); // Extract time portion
    startDateTime.set({
      hour: +startTimeOnly.split(":")[0],
      minute: +startTimeOnly.split(":")[1],
      second: +startTimeOnly.split(":")[2],
    });

    const endDateTime = moment(endDate); // Parse as ISO 8601
    const endTimeOnly = moment(endTime).format("HH:mm:ss"); // Extract time portion
    endDateTime.set({
      hour: +endTimeOnly.split(":")[0],
      minute: +endTimeOnly.split(":")[1],
      second: +endTimeOnly.split(":")[2],
    });

    const totalHours = endDateTime.diff(startDateTime, "hours", true);
    return totalHours.toFixed(2);
  },
  getDistance: (end: any, start: any) => {
    return Number(end) - Number(start);
  },
};

export default ObjectService;
