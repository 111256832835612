import { CompanyState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: CompanyState = {
  companies: [],
  company: null,
  ownCompany: null,
  loading: true,
  company_requests: [],
  companyOptions: [],
  companyCustomers: [],
  companyUsers: [],
  companyCustomersOptions: [],
  paymentOptions: [],
  selectedCompany: {},
  sorting: {},
  refreshLoader: false,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      state.refreshLoader = action.payload;
    },
    addCompany: (state, action) => {
      state.companies.unshift(action.payload);
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    updateCompany: (state, action) => {
      const { id, company } = action.payload;
      state.companies.every(({ _id }, i) => {
        if (id === _id) {
          state.companies[i] = company;
          return false;
        }
        return true;
      });
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setOwnCompany: (state, action) => {
      state.ownCompany = action.payload;
    },
    setCompanyUsers: (state, action) => {
      state.companyUsers = action.payload;
    },
    setCompanies: (state, action) => {
      const companies = action.payload;
      let options: SelectOption[] = [];
      companies.forEach(({ _id, fullName }: any) =>
        options.push({ value: _id, label: fullName })
      );
      state.companies = companies;
      state.companyOptions = options;
    },
    setCompanyCustomers: (state, action) => {
      const companyCustomers = action.payload;
      let options: SelectOption[] = [];
      companyCustomers.forEach(({ _id, first_name, last_name }: any) =>
        options.push({ value: _id, label: first_name + " " + last_name })
      );
      state.companyCustomers = companyCustomers;
      state.companyCustomersOptions = options;
    },
    setPaymentOptions: (state, action) => {
      const values = action.payload;
      let options: SelectOption[] = [];
      values.forEach((data: any) => options.push({ value: data, label: data }));
      state.paymentOptions = options;
    },
    setCompanyRequests: (state, action) => {
      state.company_requests = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    deleteCompanyRequestById: (state, action) => {
      const id = action.payload;
      state.company_requests = state.company_requests.filter(
        ({ _id }) => id !== _id
      );
    },
  },
});

const companyReducer = companySlice.reducer;

export const companyActions = companySlice.actions;
export default companyReducer;
