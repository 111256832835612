import { UberOrderState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: UberOrderState = {
  count: 0,
  refresh: 0,
  uberOrders: [],
  uberOrder: null,
  loading: true,
  uberOrderOptions: [],
  current_filters: {},
  sorting: {},
  refreshLoader: false,
  filters: {
    page: 1,
    page_size: default_page_size,
  },
};

export const uberOrderSlice = createSlice({
  name: "uberOrder",
  initialState,
  reducers: {
    clear: (state) => {
      state.uberOrderOptions = [];
      state.uberOrders = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addUberOrder: (state, action) => {
      state.uberOrders.unshift(action.payload);
    },
    updateUberOrder: (state, action) => {
      const { id, uberOrder } = action.payload;
      state.uberOrders.every(({ _id }, i) => {
        if (id === _id) {
          state.uberOrders[i] = uberOrder;
          return false;
        }
        return true;
      });
    },
    setUberOrder: (state, action) => {
      state.uberOrder = action.payload;
    },
    setUberOrders: (
      state,
      action: PayloadAction<{ uberOrders: any[]; count: number }>
    ) => {
      const { count, uberOrders } = action.payload;
      let options: SelectOption[] = [];

      uberOrders?.forEach(({ _id, fullName }: any) => {
        const option = { value: _id, label: `${fullName}` };
        options.push(option);
      });

      state.count = count;
      state.uberOrders = uberOrders;
      state.refreshLoader = false;
      // state.uberOrderOptions = options;
    },
    setPartnerUberOrders: (state, action) => {
      const uberOrders = action.payload;
      let options: SelectOption[] = [];
      uberOrders.forEach(({ _id, fullName }: any) =>
        options.push({ value: _id, label: `${fullName}` })
      );
      // state.uberOrderOptions = options;
    },
    setUberOrdersOptions: (state, action) => {
      state.uberOrderOptions = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteUberOrder: (state, action) => {
      const id = action.payload;
      state.uberOrders.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.uberOrders.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const uberOrderReducer = uberOrderSlice.reducer;

export const uberOrderActions = uberOrderSlice.actions;
export default uberOrderReducer;
