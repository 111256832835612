import http from "./http.service";
import { reset } from "redux-form";
import Promisable from "./promisable.service";
import { userActions } from "redux/slices/user";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import ObjectService from "utils/object.util";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { authActions } from "redux/slices/auth";

const url = "/admin/user";

const UserService = {
  getUser: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(userActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.setUser(user));
      dispatch?.(userActions.setLoading(false));
    } else dispatch?.(userActions.setUser({ data: "Not Found" }));

    dispatch?.(userActions.setLoading(false));
    return [success, error];
  },
  getSearchUsers: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(userActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/search`, data)
    );

    if (success) {
      const { users } = success.data.data;
      const count = users.length || 0;
      dispatch?.(userActions.setSearchUsers({ users, count }));
      dispatch?.(userActions.setLoading(false));
    } else dispatch?.(userActions.setUser({ data: "Not Found" }));

    dispatch?.(userActions.setLoading(false));
    return [success, error];
  },

  getUsers: async (data: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(userActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { users, count } = success.data.data;

      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: users.map((user: any) => ({
              Name: `${ObjectService.getFullName(user)}`,
              Phone: user.phone,
              Email: user.email,
              "Signup Date": user.createdAt,
              "Billing Address": user.billing_address.name
                ? `${user.billing_address.street_address}, ${user.billing_address.postal_number}, ${user.billing_address.city}, ${user.billing_address.country}`
                : "",
              "Have No Bookings": user.is_first_trip ? "No" : "Yes",
              Language: user.language,
              "MC Points Balance": user.yelo_points_balance,
              "Referral Balance": user.referral_balance,
              "Profile Link": user.profile_pic,
              "Referral Code": user.referral.code,
              "Referred From Code": user.referral.from,
              "Referred To Count": user.referral.to.length,
            })),
          })
        );
      else dispatch?.(userActions.setUsers({ users, count }));
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(userActions.setLoading(false));
    return [success, error];
  },

  addUser: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/adduser`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.addUser(user));
      navigate?.("/users");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateUser: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.setUser(user));
    }

    return [success, error];
  },
  updateProfile: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updateProfile`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(authActions.setUser(user));
    }
    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteUser: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(userActions.deleteUserById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateBillingAddress: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updatebillingaddress/${id}`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.setUser(user));
    }

    return [success, error];
  },

  addAdminNotes: async (id: string, data: any) => {
    const dispatch = getAppDispatch();

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/admin-notes/${id}`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.setUser(user));
    }

    return [success, error];
  },

  addPoints: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(userActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`yelo-points`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.setUser(user));
      dispatch?.(userActions.setLoading(false));
      dispatch?.(reset("AddUserPointsForm"));
    }

    dispatch?.(userActions.setLoading(false));
    return [success, error];
  },
  getLoggedUser: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(userActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/admin/user/getLoggedUser`)
    );

    if (success) {
      const { user } = success?.data?.data;
      dispatch?.(authActions.setUser(user));
    }

    dispatch?.(userActions.setLoading(false));
    return [success, error];
  },
};

export default UserService;
