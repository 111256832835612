export const config = {
  NODE_ENV: "production", // process.env.NODE_ENV,
  APP_ENV: process.env.REACT_APP_ENV,

  API_URL: process.env.REACT_APP_API_URL,
  // API_URL: process.env.REACT_APP_LOCAL_API_URL, // local
  APP_URL: `${process.env.REACT_APP_URL}`,
  SOCKET_URL: `${process.env.REACT_APP_SOCKET_URL}`,
  LOCAL_API_URL: process.env.REACT_APP_LOCAL_API_URL,

  ONE_SIGNAL_APP_ID: `${process.env.REACT_APP_ONE_SIGNAL_APP_ID}`,

  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  MUI_LICENSE_KEY: process.env.REACT_APP_MUIX_LICENSE,
  APP_HR_DRIVER_URL: process.env.REACT_APP_HR_DRIVER_URL,
  APP_HR_COMPANY_URL: process.env.REACT_APP_HR_COMPANY_URL,

  PAGE_SIZE: Number(process.env.REACT_APP_PAGE_SIZE) || 0,
  MAX_KM_PER_HOUR: Number(process.env.REACT_APP_MAX_KM_PER_HOUR) || 0,
};
