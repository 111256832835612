import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

import ErrorBoundary from "components/atoms/ErrorBoundary";
import InitializationService from "services/initialization.service";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import "moment/locale/es";
import "moment/locale/de";
InitializationService.init();
const container: any = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </ErrorBoundary>
  // </React.StrictMode>/
);

reportWebVitals();
