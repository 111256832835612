import { RevenueState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: RevenueState = {
  count: 0,
  refresh: 0,
  revenues: [],
  revenuesRaw: {},
  revenue: null,
  loading: true,
  revenueOptions: [],
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, page_size: 100 },
};

export const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    setSortRevenues: (state, action: PayloadAction<any[]>) => {
      const list = action.payload;

      state.revenues = list;
    },
    clear: (state) => {
      state.revenueOptions = [];
      state.revenues = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addRevenue: (state, action) => {
      state.revenues.unshift(action.payload);
    },

    updateRevenue: (state, action) => {
      const { id, revenue } = action.payload;
      state.revenues.every(({ _id }, i) => {
        if (id === _id) {
          state.revenues[i] = revenue;
          return false;
        }
        return true;
      });
    },
    setRevenue: (state, action) => {
      state.revenue = action.payload;
    },
    setRevenues: (
      state,
      action: PayloadAction<{ revenues: any[]; count: number }>
    ) => {
      const { count, revenues } = action.payload;

      state.count = count;
      state.revenues = revenues;
      state.refreshLoader = false;
      // state.revenueOptions = options;
    },
    setRevenuesRaw: (state, action: PayloadAction<any>) => {
      console.log("action.payload", action.payload);
      state.revenuesRaw = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    deleteRevenue: (state, action) => {
      const id = action.payload;
      state.revenues.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.revenues.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const revenueReducer = revenueSlice.reducer;

export const revenueActions = revenueSlice.actions;
export default revenueReducer;
