import { LocationState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: LocationState = {
  drivers: [],
  vehicles: [],
  loading: false,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmptyDrivers: (state, action: PayloadAction<any>) => {
      state.drivers = [];
    },
    setDriverLocation: (state, action) => {
      const driversToUpdate = action.payload;
      driversToUpdate.forEach((driverData: any) => {
        const { driverId, ...rest } = driverData;

        const driverIndex = state.drivers.findIndex(
          (item) => item.driverId === driverId
        );

        if (driverIndex !== -1) {
          // Update the existing driver data
          state.drivers[driverIndex] = {
            ...state.drivers[driverIndex],
            ...rest,
          };
        } else {
          // Add the new driver if not already present
          state.drivers.push({ driverId, ...rest });
        }
      });
    },
    setVehicleLocation: (state, action) => {
      const { vehicleId, ...rest } = action.payload;
      const vehicleIndex = state.vehicles.findIndex(
        ({ _id }) => _id === vehicleId
      );

      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex] = {
          ...state.vehicles[vehicleIndex],
          ...rest,
        };
      } else {
        state.vehicles.push({ vehicleId, ...rest });
      }
    },
  },
});

const locationReducer = locationSlice.reducer;

export const locationActions = locationSlice.actions;
export default locationReducer;
